import { queries } from "../../../breakpoints"
import styled, { keyframes } from 'styled-components';

export const StyledResourceCenterTabs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    
    min-height: 400px;
    padding: 64px 0;
`;

export const TabsWrapper = styled.div`
    display: flex;
    max-width: 1200px;
    width: 100%;
    flex-direction: column;
  @media (${queries.max_break_sm}) {
    max-width: 78%;
    margin: 0 auto;
  }
`;

export const TabsHeader = styled.div`
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px solid #D4D4DB;
    padding-left: 0px;
`;
export const TabsHeaderItem = styled.div`
    position: relative;
    display: flex;  
    min-width: 146px;
    padding: 10px;
    font-family: var(--scandia-fontFamily), 'sans-serif';
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
    justify-content: center;
    color: #000000;
    cursor: pointer;
    
    &.active{
        font-weight: 700;
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height:4px;
            left: 0px;
            bottom: -2px;
            background: var(--color-primary);

        }
    }
    @media (${queries.max_break_sm}){
      font-size: 18px;
    }
`;
export const TabsContent = styled.div`
    display: flex; 
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    
    .filter-search-results{
        display: flex;
        padding: 20px 0px 20px 0px;
        
        font-family: var(--scandia-fontFamily);
        font-size: 13px;
        font-weight: 350;
        line-height: 23px;
        letter-spacing: 0px;
        text-align: left;
        color: var(--color-secondary);
        
        span{
            cursor: pointer;
            font-family: var(--scandia-fontFamily);
            font-size: 13px;
            font-weight: 350;
            line-height: 23px;
            letter-spacing: 0px;
            text-align: left;
            &.active{
                font-weight: 700;
            }

        }
        
    }
    .tab-content-holder{
        display: flex;
        flex-direction: row;
    }
    
    .topics-sidebar{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 180px;
        
        h4{
            font-family: var(--scandia-fontFamily);
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: #000000;
            padding: 4px 0px;
        }
        .topics-tabs{
            display: none;
            width: 100%;
            flex-direction: column;
            &.active{
                display: flex;
            }
        }
        .topic-item{
            display: flex;
            width: 100%;
            padding: 4px 0px;              
            margin-top: 20px;
            font-family: var(--scandia-fontFamily);
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: left;
            color: #6B6B83;
            transition: all .24s;
            
            cursor: pointer;
    
            &.active{
                color: var(--color-primary); 
            }
            
            &:first-of-type{                
                margin-top: 10px;
            }
        }
    }
`;
export const TabsContentResults = styled.div` 
        display: flex;
        flex-direction: row;
        width: 100%;
    &.reverse-columns{
        > .tab-content{
          flex-direction: column-reverse;
        }
    }
`;
export const TabsContentItem = styled.div`
    display: none; 
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    
    padding-left: 0px; 
   /* border-left: 2px solid #EFEFF2;*/

    
    
    &.active{
        display: flex;
    }
    p{
        font-family: var(--scandia-fontFamily);
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0px;
        text-align: left;

        color: #000000;
    }
    h4{
        margin-top: 60px;
        font-family: var(--scandia-fontFamily);
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;

    }
`;
export const ResultCardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
`;
export const ResultCard = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 26px 34px;
    
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(207, 198, 198, 0.25);
    border-radius: 10px;
    cursor: pointer;
    transition: all .24s;
    
    
    &:hover{ 
        box-shadow: 0px 8px 16px rgba(207, 198, 198, 0.4);
    }
    
    &:not(:first-child){
        margin-top: 10px;
    }    
    
    img{
        max-width: 55px;
        width: auto;
        object-fit: contain;
    }
    h5{
        margin-top: 20px;
        margin-bottom: 0px;
        font-family: var(--scandia-fontFamily);
        font-size: 20px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        span{
            margin-left: 20px;
            font-family: var(--scandia-fontFamily);
            font-size: 14px;
            font-weight: 700;
            line-height: 29px;
            letter-spacing: 0px;
            text-align: left;

        }


    }
    p{
        margin-top: 12px;
        margin-bottom: 12px;
        font-family: var(--scandia-fontFamily);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
    }
    a.card-link{
        font-family: var(--scandia-fontFamily);
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.19719083607196808px;
        text-align: left;

        cursor:pointer;
        color: var(--color-primary);

        svg {
            transform: translateX(0px);
            transition: all .24s;
        }
        
        &:hover {
            svg {
              transform: translateX(4px);
            }
        }
      
    }
    &.hide{
        display: none;
    }
  
    &.card-no-filter{
      flex-order: 1;
    }
`;
export const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const LoadingSpinner = styled.div`
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid var(--color-primary);
  border-radius: 50%;
  animation: ${Spinner} 1.5s linear infinite;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1110px;
`;
