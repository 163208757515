import styled from 'styled-components';
import { queries } from "../../../breakpoints";

export const StyledResearchSearch = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    justify-content: flex-start;
`;
export const SearchResourceCenter = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    margin-top: 44px;
    margin-bottom: 44px;
    width: 100%;
    max-width: 480px;
    
    form {    
        position: relative;
        display: flex;
        width: 100%;
        
        .resourceFormSearch{
            display: flex;
            width: 100%;
            
            button{
                background: none;
                border: none;
                outline: none;
                
                position: absolute;
                top: 17px;
                right: 15px;
                width: 15px;
                height: 15px;
                
                svg{
                    position: absolute;
                    top: 0px;
                    left: 0px;
                }
            }
            
            
            
            input{
                width: 100%;
                background: #FFFFFF;
                border: 2px solid #CAC3FA;
                border-radius: 24px;
                padding: 14px 24px;
                width: 100%;
                transition: all .24s;
                
                font-family: var(--scandia-fontFamily);
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 14px;
                display: flex;
                align-items: center;
                color: #000000;
            
                &::placeholder{ 
                    color: #AEB3BB;
                }
                &:focus{
                    outline: none;
                    border: 2px solid var(--color-primary);
                }
            }
        
        }
    
    }

    @media (${queries.max_break_sm}) { 
      justify-content: center;
      margin-top: 32px !important;
      margin-bottom: 32px;
    }
`;
/*export const SearchResourceCenter = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    margin-top: 44px;
    margin-bottom: 44px;
    max-width: 288px;

    svg{
        position: absolute;
        top: 17px;
        right: 15px;
        width: 15px;
        height: 15px;
    }

    input{
        background: #FFFFFF;
        border: 2px solid #CAC3FA;
        border-radius: 24px;
        padding: 14px 24px;
        width: 100%;
        transition: all .24s;

        font-family: var(--scandia-fontFamily);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #000000;

        &::placeholder{
            color: #AEB3BB;
        }
        &:focus{
            outline: none;
            border: 2px solid var(--color-primary);
        }
    }
`;*/

