const extractImageFromText = (textWithImage) => {
    let imgSrc = "";
    if(textWithImage){
        try {
            let doc = new DOMParser().parseFromString(textWithImage, "text/html");
            let img  = doc.documentElement.querySelector("img");
            imgSrc = img.src;
        } catch (error) {
        }
    }
    return imgSrc;
}

const curateTopicDescription = (topicDescription) => {
    let curatedDescription = "";
    if(topicDescription){
        try {
            let doc = new DOMParser().parseFromString(topicDescription, "text/html");
            curatedDescription = doc.documentElement.textContent;
        } catch (error) {
        }
    }
    return curatedDescription;
}

export const buildTopicHierarchy = (
    data = [],
    {idKey='id',parentKey='parentId',childrenKey='subTopics'} = {}
) => {
    const tree = [];
    const childrenOf = {};
    data.forEach((item) => {
        let newItem = {...item};
        newItem.imageSource = extractImageFromText(newItem.description);
        newItem.description = curateTopicDescription(newItem.description);
        if(newItem.resources) {
            newItem.resources = newItem.resources.nodes;
        } else {
            newItem.resources = [];
        }
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];
        parentId
            ? (
                childrenOf[parentId] = childrenOf[parentId] || []
            ).push(newItem)
            : tree.push(newItem);
    });
    return tree;
};

export const capitalize = (string) => {
    if (string) {
        const arr = string.split("-");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        let string2 = arr.join("-");
        const arr2 = string2.split(" ");
        for (var i = 0; i < arr2.length; i++) {
            arr2[i] = arr2[i].charAt(0).toUpperCase() + arr2[i].slice(1);
        }
        return arr2.join(" ");
    } else return null;
}

export const shouldNavigateAway = (topic) => {
    let shouldNavigate = false;
    if (topic.subTopics.length !== 0){
        topic.subTopics.forEach((subTopic) => {
            if (subTopic.description === "" || !subTopic.imageSource) {
                shouldNavigate = true;
            }
        });
    } else {
        shouldNavigate = true;
    }
    return shouldNavigate;
}

export const decideToShowSecondSwitch = (selectedTopic, topicHierarchy) => {
    let shouldShow = false;
    let secondSwitchTopics = [];
    topicHierarchy.forEach((masterTopic) => {
        if (selectedTopic === masterTopic.name) {
            masterTopic.subTopics.forEach((subTopic) => {
                if (subTopic.description !== "" && subTopic.imageSource) {
                    shouldShow = true;
                    secondSwitchTopics.push(subTopic.name);
                }
            });
        }
    });
    if (shouldShow && secondSwitchTopics.length > 0) {
        return ({shouldShow: shouldShow, secondSwitchTopics: secondSwitchTopics});
    } else {
        return ({shouldShow: shouldShow, secondSwitchTopics: null});
    }
}

export const predefinedSortSubTopics = ( topic, subTopics, keyToSearch ) => {
    let subtopicsOrder = {
            'Enterprise Accounts': [
                'Getting Started', 'Employees & Groups', 'Meal Credit Settings',
                'Billing', 'Company Details', 'Orders',
                'Contact Information'
            ],
            'Self-Service Accounts': [
                'What is a Self-Service Account', 'Setup Your Account', 'Manage Your Account', 'Training Videos'
            ],
            'Employees': [
                'Getting Started', 'Using Your Meal Credits', 'Contact Information'
            ]
        },

        sortOrder = subtopicsOrder[topic];

    // Keep log for testing on production before launch
    /*console.log( '------------------' );
    console.log( 'Topic name:', topic );
    console.log( 'Topic subtopics:', subTopics );*/

    if( sortOrder !== undefined ) {
        subTopics.map( ( subTopic, i ) => {
            let indexFound = sortOrder.indexOf( subTopic[keyToSearch] );
            sortOrder [( indexFound < 0 ? sortOrder.length : indexFound )] = subTopic;
        } );
    } else {
        return subTopics;
    }

    return sortOrder ? sortOrder : subTopics;
}
