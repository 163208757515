import React from 'react';

export const SearchResourceForm = ( { searchQuery, setSearchQuery } ) => {
    return (
        <form
            onSubmit={( e ) => {

                e.preventDefault();
                console.log( 'On submit search:', `${searchQuery}` );
                window.location.replace( `/learning-center-search/?s=${searchQuery}` );
                return false;
            }}
            method="get"
            autoComplete="off"
        >
            <div className='resourceFormSearch'>
                <input
                    value={searchQuery}
                    onInput={( e ) => setSearchQuery( e.target.value )}
                    type="text"
                    id="header-search"
                    placeholder="Search for help articles"
                    name="s"
                />
                <button type="submit">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.1017 2.81395C4.9353 2.81395 3.17705 4.58144 3.17705 6.75924C3.17705 8.93705 4.9353 10.7045 7.1017 10.7045C9.26811 10.7045 11.0263 8.93705 11.0263 6.75924C11.0263 4.58144 9.26811 2.81395 7.1017 2.81395ZM13.3811 6.75924C13.3811 8.0533 12.9965 9.26056 12.3293 10.2627C13.4047 11.3437 15.8223 13.7661 15.8223 13.7661L14.1504 15.4468C14.1504 15.4468 11.7563 13.0323 10.681 11.9512C9.66057 12.6535 8.42823 13.0717 7.1017 13.0717C3.63231 13.0717 0.822266 10.2469 0.822266 6.75924C0.822266 3.27161 3.63231 0.446777 7.1017 0.446777C10.5711 0.446777 13.3811 3.27161 13.3811 6.75924Z" fill="#6B6B83"/>
                    </svg>
                </button>
            </div>
        </form>
    )
};
