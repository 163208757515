import { queries } from "../../../breakpoints"
import styled, { css } from 'styled-components';

export const StyledResourceCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    position: relative;
    background-size: cover;
    
    ${props => props.background && css`background-color: ${props.background};`};
    ${props => props.backgroundImage && css`background-image: url("${props.backgroundImage}");`};
    
    max-height: 276px;
    
    .search-resource-background{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
`;


export const Preheader = styled.h3`
    font-weight: bold;
    text-transform: uppercase;
    color: #aeaeae;
    letter-spacing: 4.14px;
    font-size: clamp(var(--fontSize-1), 1.15vw, 3rem);
    margin: 0;
`;

export const Headline = styled.h3`
    font-family: var(--scandia-fontFamily); 
    
    margin-bottom: 30px;
    font-size:clamp(var(--fontSize-2), 3.5vw, 15px);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 3.6666667461395264px;
    text-align: left;
`;

export const Subheadline = styled.h1`
    font-family: var(--scandia-fontFamily); 
    
    font-weight: bold;  
    max-width: 720px;
    font-size: clamp(var(--fontSize-3), 3.5vw, 31px); 
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0px;
    text-align: center;

`;


export const Troubleshooting = styled.div`
    margin-top: 30px;
    font-family: var(--scandia-fontFamily);
    font-size: clamp(var(--fontSize-2), 3.5vw, 18px);
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left; 
    a{
        margin-left: 4px;
        font-family: var(--scandia-fontFamily);
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
        color: var(--color-primary);
    }
`;

export const Container = styled.div`
    min-height: 276px;
    align-items: center;
    justify-content: center;
    width: 100%;
    display: flex;
    z-index: 2;
    @media (${queries.max_break_sm}){
        padding:50px 25px 0px;
    }
`;

export const Illustration = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 768px;  
    @media (${queries.max_break_sm}){
        display:none;
    }
`;

export const Content = styled.div`
    max-width: 1400px;
    padding: 40px 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
  
  > div:first-of-type{
    justify-content: center;
  }
    
    @media (${queries.max_break_sm}){
        margin-bottom: 20px;
    }
    h1{
        font-family: var(--scandia-fontFamily);
        font-size: 31px;
        font-weight: 700;
        line-height: 37px;
        letter-spacing: 0px;
        text-align: center;

    }
    p{
        font-family: var(--scandia-fontFamily);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: left;
    }

      form {
        max-width: 480px !important;
      }
`;

export const RightIllustration = styled( Illustration )`
  justify-self: end;
`;
