import React from 'react';
import { LoadingSpinner, SpinnerContainer, ResultCard, StyledResourceCenterTabs, TabsContent, TabsContentItem, TabsContentResults, TabsHeader, TabsHeaderItem, TabsWrapper } from "./ResourceSearchTabs.styles";


const ResourceSearchTabs = ( props ) => {

    const tabItemClick = ( e ) => {
        var _e = e.target;
        if( !_e.matches( '.active' ) ) {
            onClickActive( _e );
            addCustomHashInURL( _e.id, 'tab' );
            tabShowContent( Array.prototype.indexOf.call( _e.parentElement.children, _e ) );
        }
    }

    const addCustomHashInURL = ( hashValue, key ) => {
        let hashObj = getAllHashValues(),
            newHashValue = '';

        hashObj[key] = hashValue;
        let incr = 0;
        for ( const key in hashObj ) {
            if( hashObj.hasOwnProperty( key ) ) {
                newHashValue += ( incr > 0 ? '&&' : '' ) + key + '=' + hashObj[key];
            }
            incr++;
        }
        window.location.hash = newHashValue;
    }

    const getAllHashValues = () => {
        let urlHash = window.location.hash,
            hash = urlHash.split('#'),
            hashObj = {};
        hash.shift();
        hash = hash.toString().split( '&&' );
        hash.map( el => {
            let val = el.split('=');
            if( val.length == 2 ){
                hashObj[val[0]] = val[1];
            }
        } );
        return hashObj;
    }
    const cardClick = ( { target, parentElement } ) => {
        if( !target.classList.contains( 'card-link' ) ){
            let parent = target.classList.contains( 'result-card' ) ? target : target.parentElement,
                linkTag = parent.querySelector( 'a' );
            window.location.href = linkTag.getAttribute('href');
        }
    }
    const tabShowContent = ( activeIndex ) => {
        var tabContent = document.getElementById( 'tab-content-results' ),
            _content = tabContent.querySelector( '.active' );
        if( _content !== null ) {
            _content.classList.remove( 'active' );
        }
        tabContent.children[activeIndex].classList.add( 'active' );
    }

    const sidebarTopicClick = ( e ) =>{
        var _e = e.target;
        onClickActive( _e );
        onFilterTopics( _e.getAttribute( "filter" ) );
    }
    const onFilterTopics = ( filter ) => {
        var tabContent = document.getElementById( 'tab-content-results' ),
            _content = tabContent.querySelector( '.active' ),
            _children = _content.children;

        for( var i = 0; i < _children.length; i++ ){
            var child = _children[i];
            if( child.getAttribute( 'filter' ).indexOf( filter ) > -1 ) {
                child.classList.remove( 'hide' );
            } else {
                child.classList.add( 'hide' );
            }
        }
    }
    const onClickActive = ( _e ) => {
        if( !_e.matches( '.active' ) ){
            var _active = _e.parentElement.querySelector( '.active' );
            if( _active !== null ) {
                _active.classList.remove( 'active' );
            }
            _e.classList.add( 'active' );
        }
    }

    const [ filterByDate, setFilterByDate] = React.useState( false )

    const filterResultsByDate = ( e, byDate ) => {
        var _e = e.target,
            dataFilter = _e.getAttribute( 'data-filter' ),
            byDate = dataFilter === 'date';
        onClickActive( _e );
        addCustomHashInURL( dataFilter, 'filter' );
        setFilterByDate( byDate );
    }

    const arrowLink = () => {
        return (
            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.9914 5.92545L14.9754 7.89162L12.9914 9.86575L7.7194 15.1115L5.7434 13.1374C5.7434 13.1374 8.2634 10.8528 9.9834 9.30057H0.575397V6.5145H10.0154C8.3114 4.97022 5.7834 2.68565 5.7834 2.68565L7.7594 0.711517L12.9914 5.92545Z" fill="#6B6B83"/>
            </svg>
        )
    };

    let searchResults = null;
    if( props.searchResult ){
        searchResults = props.searchResult.resultsByParentTopic;
    }

    let filterBy;
    if( typeof window !== "undefined" && window.location.hash ) {
        let hashFilter = getAllHashValues()['filter']
        filterBy = hashFilter ? hashFilter : 'relevance';
    } else {
        filterBy = 'relevance';
    }

    const sortResultsByDate = ( x, y ) =>{

        let x_Date = parseInt( x.filter_date ),
            y_Date = parseInt( y.filter_date );

        if( x_Date < y_Date ) {
            return 1;
        }
        if( x_Date > y_Date ) {
            return -1;
        }
        return 0;
    }

    let tabSelected = 0;

    return (
        <StyledResourceCenterTabs style={{ background: props.background, color: props.textColor, ...props.style }} hasChildren={props.children}>
            {
                props.searchInProgress ? (
                    <SpinnerContainer id="marketo-loading-spinner">
                                      <LoadingSpinner/>
                                    </SpinnerContainer>
                ) :
                (
                    <>
                        <TabsWrapper>
                            <TabsHeader>
                                 {
                                     ( searchResults ) && searchResults.map( ( resultTopic, i ) => {
                                         let idTab = resultTopic.name.replace( ' ', '-' ).toLowerCase(),
                                            hashTab = getAllHashValues()['tab'],
                                            clsActive = ' active';
                                            if( hashTab === undefined ){
                                                if( i !== 0 ){
                                                    clsActive = '';
                                                }
                                            } else {
                                                if( hashTab !== idTab ){
                                                    clsActive = '';
                                                }
                                            }

                                            if( clsActive !== '' ){
                                                tabSelected = i;
                                            }
                                         return <TabsHeaderItem id={idTab} className={`tab-item ${clsActive}`} onClick={( e ) => tabItemClick( e )}>{resultTopic.name}</TabsHeaderItem>
                                     } )
                                 }
                            </TabsHeader>
                            <TabsContent id="res-tab-content">
                                <div className={"filter-search-results"}>
                                    <div id={"results-filter"}>Filter by <span className={filterBy === 'date' ? 'active' : ''} onClick={(e) => filterResultsByDate( e )} data-filter={"date"}>Date</span> | <span className={filterBy === 'relevance' ? 'active' : ''} onClick={( e ) => filterResultsByDate( e )} data-filter={"relevance"}>Relevance</span></div>
                                </div>
                                <div className={"tab-content-holder"}>
                                    <TabsContentResults id={"tab-content-results"}>
                                        {
                                            ( searchResults ) && searchResults.map( ( resultTopic, i ) => {
                                                let resultsArray = [...resultTopic.searchResults],
                                                    validResults = resultsArray.length > 0;

                                                if( validResults && filterBy === 'date' ){
                                                    resultsArray.sort( sortResultsByDate );
                                                }

                                                return (
                                                    <TabsContentItem className={`tab-content ${i === tabSelected ? ' active' : ''}`}>
                                                    {
                                                        ( validResults ) ? (
                                                            resultsArray.map( result => {
                                                            return (
                                                                <ResultCard className={"result-card"} onClick={( e ) => cardClick( e )}  >
                                                                    <h5 >{result.post_title}<span>{result.post_date}</span><span>{result.order}</span></h5>
                                                                    <p>{result.description}</p>
                                                                    <a href={window.location.origin + '/resource/' + result.post_name} className="card-link">Read more</a>
                                                                </ResultCard>
                                                            )
                                                        } ) ) : ( <p>No results were found!</p> )
                                                    }
                                                    </TabsContentItem>
                                                )
                                            } )
                                        }
                                    </TabsContentResults>
                                </div>
                            </TabsContent>
                        </TabsWrapper>
                    </>
                )
            }

        </StyledResourceCenterTabs>
    )
};

export default ResourceSearchTabs;
