import Layout from "../components/global/Layout";
import React, { useState, useEffect } from "react";
import fetch from "node-fetch";
import { useStaticQuery, graphql } from "gatsby";
import { buildTopicHierarchy, shouldNavigateAway } from "../templates/resourcesHelperFunctions";
import ResourceSearchResultHeader from "../components/global/ResourceSearchHeader/ResourceSearchResultHeader";
import ResourceSearchTabs from "../components/global/ResourceSearchTabs/ResourceSearchTabs";

const LearningCenterSearch = ( { location } ) => {
    const rawData = useStaticQuery( graphql`
        query WordPressTopicsQuery2 {
            wp: wp {
                allSettings {
                    generalSettingsUrl
                }
            }
            allTopics: allWpTopic {
            nodes {
              id
              name
              description
              parentId
              resources{
                nodes{
                    id
                }
              }
            }
            }          
          
        }
    ` );

    const [ topicHierarchy, setTopicHierarchy ] = React.useState( buildTopicHierarchy( rawData.allTopics.nodes ) );
    const [ cards, setCards ] = React.useState( [] );

    let query;
    if( typeof window !== "undefined" ) {
        const { search } = window.location;
        query = new URLSearchParams( search ).get( 's' )
    }
    const [ searchQuery, setSearchQuery ] = React.useState( query || '' );

    const [ searchInProgress, setSearchInProgress ] = React.useState( false );
    const [ searchResult, setSearchResult ] = React.useState( null );


    const requestOptions = {
        //mode: 'no-cors',
        //credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify( { query: '{searchQuery(name: "' + searchQuery + '")}' } )
    };

    const reqeustSearchQuery = async () => {
        const data = await fetch( rawData.wp.allSettings?.generalSettingsUrl + '/graphql', requestOptions )
                .then( response => {
                    return response.json();
                } )
                .catch( error => {
                    console.log( error );
                } );

        if( data !== undefined ){
            if( data.errors ) {
                data.errors.forEach( error => {
                    console.error( error );
                } );
            } else {
                if( data.data.searchQuery ){
                    setSearchResult( JSON.parse( data.data.searchQuery ) );
                }
                if( searchResult === null ) {
                    //console.info( 'SEARCH results NULL' )
                }
            }
        }


    };
    if( searchInProgress === false && searchResult === null ) {
        setSearchInProgress(true );
        reqeustSearchQuery();
    }

    if( searchResult && searchInProgress ){
        setSearchInProgress( false );
    }


    const updateCards = ( topicArray ) => {
        let newCards = [];
        topicArray.forEach( ( subTopic ) => {
            newCards.push( {
                title: `${subTopic.name}`,
                description: subTopic.description,
                link: `/resources/?resource_type=${subTopic.name}`,
                imageSrc: subTopic.imageSource,
                linkText: "See all",
                subTopics: subTopic.subTopics,
                search: [],
                action: shouldNavigateAway( subTopic ) ?
                    () => {
                        console.log( 'Change window location' );
                        //window.location.href = `/resources/?resource_type=${subTopic.name}`
                    } :
                    () => {
                        setCards( updateCards( subTopic.subTopics ) )
                    },
            } );
        } );
        return newCards;
    }

    React.useEffect( () => {
        if( topicHierarchy && cards.length === 0 ) {
            setCards( updateCards( topicHierarchy ) );
        } else if( !topicHierarchy ) {
            rawData.allTopics.nodes.forEach( element => {
                element.used = false;
            } );
            setTopicHierarchy( buildTopicHierarchy( rawData.allTopics.nodes ) );
        }
    }, [ topicHierarchy, rawData ] );

    return (
        <Layout
            location={location}
            title={"Learning Center — Grubhub Corporate"}
            description={"Questions about how a Grubhub corporate account works? Check out our resources center where we provide the information account admins and employees need including step-by-step instructions for using a corporate account."}
            className="learning-center-page"
            utag_data={{
                pageGroup: "",
                subGroup: "",
                pageName: "",
                brand: "grubhub"
            }}
        >


      <ResourceSearchResultHeader
          subheadline="Easily find what you’re looking for"
          backgroundImage="https://media-cdn.grubhub.com/grubhub-marketing/image/upload/v1674233922/Corporate/ojxs5fz5aedsaqtf2nqx.png"
          backgroundColor="#F9F9F9"
          searchSpacingTop="20px"
          rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1664392111/HERMES/2022/B2B/GHCORP/B2B-Corp-20220908-CLC-Updates/Subpage/Enterprise/DSK/DSK_Enterprise_Subpage_Header_Orders.png"
          mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1652367970/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/May%2011%20Handoff%20-%20NO%20HOUSE%20UPDATE/How%20It%20Works/MOBILE-HowItWorks-HeaderImage.png"
          mobileSwitchEarlier={true}
      />
      <ResourceSearchTabs
          backgroundColor="#ffffff"
          cards={cards}
          searchResult={searchResult}
          searchInProgress={searchInProgress}
      ></ResourceSearchTabs>
    </Layout>
    );
}

export default LearningCenterSearch


