import React, { useContext } from 'react';
import PropTypes from "prop-types";
import { SearchResourceForm } from "../ResourceSearchHeader/search-resource";
import { SearchResourceCenter, StyledResearchSearch } from "./ResourceSearch.styles";

const ResearchSearch = ( props ) => {

    let query;
    if( typeof window !== "undefined" ) {
        const { search } = window.location;
        query = new URLSearchParams( search ).get( 's' )
    }
    const [ searchQuery, setSearchQuery ] = React.useState( query || '' );

    return (
        <StyledResearchSearch>
            <SearchResourceCenter style={{ marginTop: props.searchSpacingTop }}>
                <SearchResourceForm
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    style={{ marginTop: props.searchSpacingTop }}
                />
            </SearchResourceCenter>
        </StyledResearchSearch>
    )
};

ResearchSearch.propTypes = {
    searchSpacingTop: PropTypes.string
};

ResearchSearch.defaultProps = {
    searchSpacingTop: ""
};

export default ResearchSearch;
